import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Pisces.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Pisces Facts
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/pisces"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Pisces </h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Pisces Facts</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Pisces Facts </h1>
                <h2 className="text-sm md:text-base ml-4">Feb 19 - Mar 20</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Pisces Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Pisces Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Pisces Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-love");
                  }}>
                  <h5 className="font-semibold text-black">Pisces Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-nature");
                  }}>
                  <h5 className="font-semibold text-black">Pisces Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-man");
                  }}>
                  <h5 className="font-semibold text-black">Pisces Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Pisces Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-traits");
                  }}>
                  <h5 className="font-semibold text-black">Pisces Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-facts");
                  }}>
                  <h5 className="font-semibold text-black">Pisces Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
                 <p className="playfair text-black text-xl md:text-3xl">Let's take a look at some interesting facts about Pisces.</p><br/>
                 <p className="playfair text-black text-xl md:text-3xl"> 1. Pisces is an inspiration</p><br/>
                 Pisces are born inspirational and magical individuals. Her sensitivity, humility and romantic nature make her very likeable. We also judge people on the inside rather than on the outside. <br/>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl">2. Pisces are active people</p><br/>
                 Pisces are often most at home in the realm of fantasies and dreams, and they usually have wonderfully active personalities. They are very open-minded about their work and personal lives and want to be involved in their work as much as possible.  <br/>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl">3. Pisces are compassionate</p><br/>
                 People born under the sign of Pisces are very caring, compassionate, empathetic, and sensitive to the feelings of others. They do their best not to intentionally hurt anyone. <br/>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl">4. Pisces are smart at work</p><br/>
                 Pisces knows exactly how to get things done on time at work. You are a smart person who knows all the tactics to influence people, get work done, and maintain your reputation.  <br/>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl">5. Pisces are helpful</p><br/>
                 Pisces people never hesitate to help others, whether at work or elsewhere. Try to help people whenever and wherever they need it. <br/>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl">6. Pisces believe in true love</p><br/>
                 Pisces in love believe that love is a miracle and completes a person. They will probably submit completely to their loved ones. They are passionate lovers who value a spiritual connection more than a physical one. <br/>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl">7. Pisces is a system that supports loved ones</p><br/>
                 People born under the zodiac sign Pisces are a pillar of strength for their loved ones when they are surrounded by difficult situations. <br/>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl">8. Pisces are very romantic</p><br/>
                 People born under the Pisces zodiac sign are very romantic when in love. They try to make every moment with their loved ones unforgettable and do typical romantic things.When they fall in love, it's like falling in love.<br/>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl">9. Pisces are open-minded</p><br/>
                 Pisces are known to be very outgoing and never criticize others for their mistakes. You know how to take people for who they are.  <br/>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl">10. Pisces are aesthetic</p><br/>
                 As a water sign, Pisces is the most creative sign of the zodiac. Pisces people are very creative and there is no limit to their creativity. You have an artistic flair and can see things very imaginatively and aesthetically. <br/>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl">11. Pisces is a bright and cheerful person</p><br/>
                 Pisces are very happy personalities. They have a very jovial and cheerful personality. Thanks to her bright personality, people around her always feel comfortable. <br/>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl">12. Pisces are moody</p><br/>
                 People born under the Pisces horoscope sign are well known for being smart and prone to moodiness. If they're in a bad mood, it's best to give them space. Half the time when they're moody, they don't even know why.<br/>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl">13. Pisces are emotional</p><br/>
                 Pisces are very emotional from the inside. If they see tears, they will assumably cry. If they can feel the pain in your voice, they will be sad too. They are unconditionally attached to you.<br/>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl">14. Pisces are lazy</p><br/>
                 Pisces are very lazy when they have nothing to do. They are basically seen as emotionally lazy because they never try to make things right.  <br/>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl">15. Pisces are very negative at times</p><br/>
                 When Pisces are down, they feel all the negative thoughts at once. They don't get over it easily and often have longer recovery times after bad experiences, during which they can become negative.<br/>
                 Pisces always invent bad things in their heads and think too much. This usually makes me feel sick or confused. <br/>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl">16. Lucky number</p><br/>
                 The lucky numbers for the zodiac sign of Pisces are – 3, 7, 12, 16, 21, 25, 30, 34, 43, 52<br/>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl">17. Lucky Day</p><br/>
                 Monday and Thursday both are lucky days for Pisces<br/>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl">18. Lucky Color</p><br/>
                 Lucky colors for Pisces are mauve, lilac, violet and sea green. <br/>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl">19. Lucky stone</p><br/>
                 Aquamarines, bloodstones, emeralds and pearls are considered lucky stones for the Pisces zodiac sign.  <br/>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl">20. Constellation compatible with Pisces</p><br/>
                 Pisces is compatible with Taurus, Cancer, Scorpio and Capricorn <br/>
                 <br></br>
                 <p className="playfair text-black text-xl md:text-3xl"> 21. Ruling Planet</p><br/>
                 Jupiter rules the zodiac sign Pisces, and Jupiter is the planet of wisdom and spirituality.<br/>
                 <br></br>
                 For further queries, connect to the best and most authentic Astrologer.<br/>
                 <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
